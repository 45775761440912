<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('MENU.status_mapping') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

          <div class="row">
            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('status_mapping.module') }}<span class="text-danger">*</span></label>
                    <select type="text" v-model="data.module_id" class="custom-select" @change="setModuleSlug" :class="validation && validation.module_id ? 'is-invalid' : ''">
                      <option v-for="(row, index) in module_type_list" :value="row.id" :key="'module_type'+index">{{row.name}}</option>
                    </select>
                    <span v-if="validation && validation.module_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.module_id[0] }}
                            </span>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('status_mapping.module_slug') }}<span class="text-danger">*</span></label>
                    <input type="text" v-model="data.slug" class="form-control" :class="validation && validation.slug ? 'is-invalid' : ''" :placeholder="$t('status_mapping.module_slug')"/>
                    <span v-if="validation && validation.slug" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.slug[0] }}
                            </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('status_mapping.document') }}<span class="text-danger">*</span></label>
                    <select type="text" v-model="data.document_id" @change="setDocSlug" class="custom-select" :class="validation && validation.document_id ? 'is-invalid' : ''">
                      <option v-for="(row, index) in doc_type_list" :value="row.id" :key="'document_id'+index">{{row.document_name}}</option>
                    </select>
                    <span v-if="validation && validation.document_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.document_id[0] }}
                            </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('status_mapping.document_slug') }}<span class="text-danger">*</span></label>
                    <input type="text" v-model="data.document_slug" class="form-control" :class="validation && validation.document_slug ? 'is-invalid' : ''" :placeholder="$t('status_mapping.document_slug')"/>
                    <span v-if="validation && validation.document_slug" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.document_slug[0] }}
                            </span>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('status_mapping.default_initial_status') }}<span class="text-danger">*</span></label>
                    <select type="text" v-model="data.initial_status" class="custom-select" :class="validation && validation.initial_status ? 'is-invalid' : ''">
                      <option v-for="(row, index) in status_data_list" :value="row.id" :key="'initial_status'+index">{{row.name}}</option>
                    </select>
                    <span v-if="validation && validation.initial_status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.initial_status[0] }}
                            </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('status_mapping.default_final_status') }}<span class="text-danger">*</span></label>
                    <select type="text" v-model="data.final_status" class="custom-select" :class="validation && validation.final_status ? 'is-invalid' : ''">
                      <option v-for="(row, index) in status_data_list" :value="row.id" :key="'final_status'+index">{{row.name}}</option>
                    </select>
                    <span v-if="validation && validation.final_status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.final_status[0] }}
                            </span>
                  </div>


                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">


                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('status_mapping.required_status') }}</label>
                    <multiselect
                        v-model="data.required_status"
                        :placeholder="$t('status_mapping.required_status')"
                        label="name"
                        track-by="id"
                        :options="status_data_list"
                        :multiple="true"
                        :taggable="false"
                        :show-labels="false"
                        :show-no-options="false"
                        :show-no-results="false">
                    </multiselect>
                    <span v-if="validation && validation.required_status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.required_status[0] }}
                            </span>
                  </div>

                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('status_mapping.optional_status') }}</label>
                    <multiselect
                        v-model="data.optional_status"
                        :placeholder="$t('status_mapping.optional_status')"
                        label="name"
                        track-by="id"
                        :options="status_data_list"
                        :multiple="true"
                        :taggable="false"
                        :show-labels="false"
                        :show-no-options="false"
                        :show-no-results="false">
                    </multiselect>
                    <span v-if="validation && validation.optional_status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.optional_status[0] }}
                            </span>
                  </div>


                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('status_mapping.multi_initial_status') }}</label>
                    <multiselect
                        v-model="data.multi_initial_status"
                        :placeholder="$t('status_mapping.multi_initial_status')"
                        label="name"
                        track-by="id"
                        :options="status_data_list"
                        :multiple="true"
                        :taggable="false"
                        :show-labels="false"
                        :show-no-options="false"
                        :show-no-results="false">
                    </multiselect>
                    <span v-if="validation && validation.multi_initial_status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.multi_initial_status[0] }}
                            </span>
                  </div>

                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('status_mapping.multi_final_status') }}</label>
                    <multiselect
                        v-model="data.multi_final_status"
                        :placeholder="$t('status_mapping.multi_final_status')"
                        label="name"
                        track-by="id"
                        :options="status_data_list"
                        :multiple="true"
                        :taggable="false"
                        :show-labels="false"
                        :show-no-options="false"
                        :show-no-results="false">
                    </multiselect>
                    <span v-if="validation && validation.multi_final_status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.multi_final_status[0] }}
                            </span>
                  </div>


                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('status_mapping.notes') }}</label>
                    <textarea class="form-control" v-model="data.notes"></textarea>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label>&ensp;</label>
                    <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-lg-12 pt-8">
              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('status_mapping.disallowed_convert_status') }}</h6>
                <button type="button" class="btn btn-primary" @click="addToRepeater">{{ $t('add_more') }}</button>
              </div>
              <div class="form-group">
                <div class="bg-white">
                  <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addToRepeater" @keyup.alt.46="deleteFromRepeater(0)">
                    <thead>
                    <tr>
                      <th>{{ $t('status_mapping.from_status') }}</th>
                      <th>{{ $t('status_mapping.to_status') }}</th>
                      <th>{{ $t('status_mapping.is_active') }}</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr v-for="(doc, index) in disallowed_convert_status" :key="index">
                      <td>
                        <select type="text" v-model="doc.from_status" class="custom-select">
                          <option v-for="(row, index) in status_data_list" :value="row.id" :key="'from_status'+index">{{row.name}}</option>
                        </select>
                      </td>
                      <td>
                        <select type="text" v-model="doc.to_status" class="custom-select">
                          <option v-for="(row, index) in status_data_list" :value="row.id" :key="'to_status'+index">{{row.name}}</option>
                        </select>
                      </td>
                      <td>
                        <b-form-checkbox size="lg" v-model="doc.is_active" name="check-button" switch></b-form-checkbox>
                      </td>
                      <td class="text-center">
                        <v-icon class="text-danger" @click="deleteFromRepeater(index)">mdi-delete</v-icon>
                      </td>
                    </tr>


                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-12 pt-8">
              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('status_mapping.event_status') }}</h6>
                <button type="button" class="btn btn-primary" @click="addToRepeaterEvent">{{ $t('add_more') }}</button>
              </div>
              <div class="form-group">
                <div class="bg-white">
                  <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addToRepeaterEvent" @keyup.alt.46="deleteFromRepeaterEvent(0)">
                    <thead>
                    <tr>
                      <th>{{ $t('status_mapping.from_status') }}</th>
                      <th>{{ $t('status_mapping.to_status') }}</th>
                      <th>{{ $t('status_mapping.event') }}</th>
                      <th>{{ $t('status_mapping.is_required') }}</th>
                      <th>{{ $t('status_mapping.is_active') }}</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr v-for="(doc, index) in event_details_status_mapping" :key="index">
                      <td>
                        <select type="text" v-model="doc.from_status" class="custom-select">
                          <option v-for="(row, index) in status_data_list" :value="row.id" :key="'from_status'+index">{{row.name}}</option>
                        </select>
                      </td>
                      <td>
                        <select type="text" v-model="doc.to_status" class="custom-select">
                          <option v-for="(row, index) in status_data_list" :value="row.id" :key="'to_status'+index">{{row.name}}</option>
                        </select>
                      </td>
                      <td>
                        <multiselect
                            v-model="doc.status_event"
                            :placeholder="$t('status_mapping.event')"
                            label="name"
                            track-by="id"
                            :options="events_list"
                            :multiple="true"
                            :taggable="false"
                            :show-labels="false"
                            :show-no-options="false"
                            :show-no-results="false">
                        </multiselect>
                      </td>
                      <td>
                        <b-form-checkbox size="lg" v-model="doc.is_required" name="check-button" switch></b-form-checkbox>
                      </td>
                      <td>
                        <b-form-checkbox size="lg" v-model="doc.is_active" name="check-button" switch></b-form-checkbox>
                      </td>
                      <td class="text-center">
                        <v-icon class="text-danger" @click="deleteFromRepeaterEvent(index)">mdi-delete</v-icon>
                      </td>
                    </tr>


                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from",
  data() {
    return {
      mainRoute: 'settings/status_mapping',
      mainRouteDependency: 'base/dependency',
      items: [],

      idEdit: this.$route.params.id ? this.$route.params.id : null,
      data: {

        module_id: null,
        slug: null,
        document_id: null,
        document_slug: null,
        initial_status: null,
        final_status: null,
        required_status: [],
        optional_status: [],
        multi_initial_status: [],
        multi_final_status: [],
        is_active: true,
        notes: null,
      },
      isEditing: false,
      validation: null,
      repeater_disallowed_convert_status: {id: null, from_status: null, to_status: null, is_active: false},
      disallowed_convert_status: [],
      repeater_event_details_status_mapping: {id: null, from_status: null, to_status: null, is_active: false, is_required: false, status_event: []},
      event_details_status_mapping: [],

      module_type_list: [],
      doc_type_list: [],
      status_data_list: [],
      events_list: [],



    };
  },
  watch:{
    'data.module_id': function (val){
      if (val){
         this.getDocType(val)
      }else {
        this.doc_type_list = [];
      }
    },
    'doc_type_list': function (val){
      if (val){
          this.getStatusDataList(this.data.document_slug);
      }else {
        this.status_data_list = [];
      }
    },
    'data.document_slug': function (val){
      if (val){
          this.getStatusDataList(val);
      }else {
        this.status_data_list = [];
      }
    },
  },
  methods: {
    setModuleSlug(event){
      let _val = event.target.value;
      let mod = this.module_type_list.find((row) => row.id == _val);
      if (mod){
        this.data.slug = mod.slug;
      }
    },
    setDocSlug(event){
      let _val = event.target.value;
      let doc = this.doc_type_list.find((row) => row.id == _val);
      if (doc){
        this.data.document_slug = doc.document_slug;
      }

    },
    setStatusInRepeater(event, index){
      let _val = event.target.value;
      let doc = this.status_data_list.find((row) => row.id == _val);
      if (doc){
        this.status_data[index].status_slug = doc.slug;
      }
    },
    save() {
      if (this.isEditing) {
        this.update();
      }
      // else {
      //   this.create();
      // }
    },

    // create() {
    //
    //   ApiService.post(this.mainRoute, {
    //     ...this.data,
    //     disallowed_convert_status: this.disallowed_convert_status,
    //     event_details_status_mapping: this.event_details_status_mapping,
    //   }).then((response) => {
    //     this.validation = null;
    //     this.$successAlert(response.data.message);
    //     this.$router.push('/settings/status-mapping');
    //   }).catch((error) => {
    //     this.$errorAlert(error);
    //     this.validation = error.response ? error.response.data.errors : null;
    //   });
    // },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
        disallowed_convert_status: this.disallowed_convert_status,
        details_mapping: this.event_details_status_mapping,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/settings/status-mapping');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.module_id = response.data.data.module_id;
        this.data.slug = response.data.data.slug;
        this.data.document_id = response.data.data.document_id;
        this.data.document_slug = response.data.data.document_slug;
        this.data.initial_status = response.data.data.initial_status;
        this.data.final_status = response.data.data.final_status;
        this.data.required_status = response.data.data.required_status;
        this.data.optional_status = response.data.data.optional_status;
        this.data.multi_initial_status = response.data.data.multi_initial_status;
        this.data.multi_final_status = response.data.data.multi_final_status;
        this.data.is_active = response.data.data.is_active;
        this.data.notes = response.data.data.notes;

        this.disallowed_convert_status = response.data.data.disallowed_convert_status ? response.data.data.disallowed_convert_status : [];
        this.event_details_status_mapping = response.data.data.details_mapping ? response.data.data.details_mapping : [];
      });
    },

    addToRepeater() {
      this.disallowed_convert_status.unshift(this.repeater_disallowed_convert_status);
      this.repeater_disallowed_convert_status = {id: null, from_status: null, to_status: null, is_active: false};
    },
    deleteFromRepeater(index) {
      if (this.disallowed_convert_status.length > 1)
        this.disallowed_convert_status.splice(index, 1);
    },

    addToRepeaterEvent() {
      this.event_details_status_mapping.unshift(this.repeater_event_details_status_mapping);
      this.repeater_event_details_status_mapping = {id: null, from_status: null, to_status: null, is_active: false, is_required: false, status_event: []};
    },
    deleteFromRepeaterEvent(index) {
      if (this.event_details_status_mapping.length > 1)
        this.event_details_status_mapping.splice(index, 1);
    },

    async getModuleType() {
      await ApiService.get(this.mainRouteDependency+"/modules").then((response) => {
        this.module_type_list = response.data.data
      });
    },
    async getEventsList() {
      await ApiService.get(this.mainRouteDependency+"/event_managements").then((response) => {
        this.events_list = response.data.data
      });
    },
    async getDocType(module_type) {
      if (module_type){
        let _slug = '';
        let _row = this.module_type_list.find(row => row.id == module_type);
        if (_row){
          _slug = _row.slug;
          await ApiService.get(this.mainRouteDependency+"/module_document?module_type="+_slug).then((response) => {
            this.doc_type_list = response.data.data
          });
        }

      }

    },
     getStatusDataList(doc_slug) {
      if (doc_slug) {
        let _slug_for_status = '';
        let _row = this.doc_type_list.find((row) => row.document_slug == doc_slug);

        if (_row){
          _slug_for_status = _row.slug_for_status;
          ApiService.get(this.mainRouteDependency+"/document_status?doc_slug="+_slug_for_status).then((response) => {
            this.status_data_list = response.data.data
          });
        }


      }

    },



  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.status_mapping"), route: '/settings/status-mapping'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    this.getEventsList();
    let promise = this.getModuleType();
    if (this.idEdit) {
      Promise.all([promise]).then(()=>{
        this.getData();
      })

    }
    // else {
    //   if (this.data_items.length <= 0) {
    //     this.addToRepeater();
    //   }
    // }

  },
};
</script>


